<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0">
        <banner
          title="PROJECTS"
          :breadcrumb="[
            {
              label: 'professional',
              
            },
            { label: 'Projects' },
          ]"
        >
        </banner>
        <div class="row" style="margin-top: -45px">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <!-- pc responsive -->
            <div
              class="card custom-card d-none d-md-block d-xl-block"
              style="
                font-family: 'Poppins', sans-serif;
                color: #00364f;
          
              "
            >
              <div class="card-body">
                <h4
                  style="
                    color: #00364f;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 700;
                  "
                >
                  <b
                    v-if="project.service"
                    style="
                      color: #00364f;
                      font-family: 'Poppins', sans-serif;
                      font-size: 1.2rem;
                    "
                  >
                    {{ project.service.name }}</b
                  >
                </h4>

                <strong> Modules: </strong>
                <span
                  style="text-transform: uppercase"
                  v-for="(module1, index) in serviceModules"
                  :key="index"
                  >{{ module1.moduleName }}, &nbsp;</span
                >
                <div class="row" style="margin-left: 0px; margin-right: 0px">
                  <div class="col">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>Code</b>
                    </h4>
                    <span style="color: #00364f; font-family: 'Poppins', sans-serif"
                      ><strong>{{ project.clientServiceNo }}</strong></span
                    >
                  </div>
                  <div class="col">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>Project Date</b>
                    </h4>
                    <span style="color: #00364f; font-family: 'Poppins', sans-serif"
                      ><strong>{{ dateTime(project.date) }}</strong></span
                    >
                  </div>
                  <div class="col">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>Client Phone No.</b>
                    </h4>
                    <span
                      style="color: #00364f; font-family: 'Poppins', sans-serif"
                      v-if="project.client"
                      ><strong>{{ project.client.phone }}</strong></span
                    >
                  </div>

                  <div class="col">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>Client Email</b>
                    </h4>
                    <span
                      style="color: #00364f; font-family: 'Poppins', sans-serif"
                      v-if="project.client"
                      ><strong>{{ project.client.email }}</strong></span
                    >
                  </div>
                  <div class="col">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>&nbsp;&nbsp;Due Date</b>
                    </h4>
                    <span style="color: #00364f; font-family: 'Poppins', sans-serif"
                      ><strong>{{ dateTime(project.dueDate) }}</strong></span
                    >
                  </div>
                  <div class="col">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>CA Share</b>
                    </h4>
                    <span style="color: #00364f; font-family: 'Poppins', sans-serif"
                      ><b>{{
                        project.professionalFee + project.professionalFeeGst
                      }}</b></span
                    >
                  </div>

                  <div class="col">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>Status </b>
                    </h4>

                    <p
                                  v-if="project.status == 'assigned'"
                                  class="btn btn-sm"
                                  style="
                                    width: 60px;
                                    background-color: #00364f;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>{{ project.status }}</b>
                                </p>
                                <p
                                  v-if="project.status == 'completed'"
                                  class="btn btn-sm"
                                  style="
                                    width: 90px;
                                    background-color: #008000;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>{{ project.status }}</b>
                                </p>

                                <p
                                  v-if="project.status == 'pending'"
                                  class="btn btn-sm"
                                  style="
                                    width: 60px;
                                    background-color: #cdcdcd;
                                    color: white;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>New</b>
                                </p>
                                <p
                                  v-if="project.status == 'processed'"
                                  class="btn btn-sm"
                                  style="
                                    width: 80px;
                                    background-color: #ffaa00;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>{{ project.status }}</b>
                                </p>
                                <p
                                  v-if="project.status == 'hold'"
                                  class="btn btn-sm"
                                  style="
                                    width: 60px;
                                    background-color: #8f8d8e;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>{{ project.status }}</b>
                                </p>
                                <p
                                  v-if="project.status == 'rejected'"
                                  class="btn btn-sm"
                                  style="
                                    width: 60px;
                                    background-color: #00364f;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>{{ project.status }}</b>
                                </p>
                                <p
                                  v-if="project.status == 'cancelled'"
                                  class="btn btn-sm"
                                  style="
                                    width: 80px;
                                    background-color: #f21000;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>{{ project.status }}</b>
                                </p>
                                <p
                                  v-if="project.status == 'upgrade'"
                                  class="btn btn-sm"
                                  style="
                                    width: 60px;
                                    background-color: #ee0def;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>{{ project.status }}</b>
                                </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- mobile responsive -->
            <div
              class="card custom-card d-block d-sm-block d-xl-none d-md-none"
              style="
                font-family: 'Poppins', sans-serif;
                color: #00364f;
              "
            >
              <div class="card-body">
                <h4
                  style="
                    color: #00364f;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 700;
                  "
                >
                  <b
                    v-if="project.service"
                    style="
                      color: #00364f;
                      font-family: 'Poppins', sans-serif;
                      font-size: 1.2rem;
                    "
                  >
                    {{ project.service.name }}</b
                  >
                </h4>

                <strong> Modules: </strong>
                <span
                  style="text-transform: uppercase"
                  v-for="(module1, index) in serviceModules"
                  :key="index"
                  >{{ module1.moduleName }}, &nbsp;</span
                >
                <div class="row mt-1">
                  <div class="col-6">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>Code</b>
                    </h4>
                    <span style="color: #00364f; font-family: 'Poppins', sans-serif"
                      ><strong>{{ project.clientServiceNo }}</strong></span
                    >
                  </div>
                  <div class="col-6">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>Project Date</b>
                    </h4>
                    <span style="color: #00364f; font-family: 'Poppins', sans-serif"
                      ><b>{{ dateTime(project.date) }}</b></span
                    >
                  </div>
                  <div class="col-6">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>Client Phone No.</b>
                    </h4>
                    <span
                      style="color: #00364f; font-family: 'Poppins', sans-serif"
                      v-if="project.client"
                      ><strong>{{ project.client.phone }}</strong></span
                    >
                  </div>

                  <div class="col-6">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>Client Email</b>
                    </h4>
                    <span
                      style="color: #00364f; font-family: 'Poppins', sans-serif"
                      v-if="project.client"
                      ><strong>{{ project.client.email }}</strong></span
                    >
                  </div>
                  <div class="col-6">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>&nbsp;&nbsp;Due Date</b>
                    </h4>
                    <span style="color: #00364f; font-family: 'Poppins', sans-serif"
                      ><b>{{ dateTime(project.dueDate) }}</b></span
                    >
                  </div>
                  <div class="col-6">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>CA Share</b>
                    </h4>
                    <span style="color: #00364f; font-family: 'Poppins', sans-serif"
                      ><b>{{
                        project.professionalFee + project.professionalFeeGst
                      }}</b></span
                    >
                  </div>
                  <div class="col-6">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>CA Share </b>
                    </h4>
                    <span style="color: #00364f; font-family: 'Poppins', sans-serif"
                      ><b>{{
                        project.professionalFee + project.professionalFeeGst
                      }}</b></span
                    >
                  </div>
                  <div class="col-6">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>Status </b>
                    </h4>

                    <p
                      v-if="project.status == 'pending'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: gray;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>New</b>
                    </p>
                    <p
                      v-if="project.status == 'assigned'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #349c9e;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>
                    <p
                      v-if="project.status == 'completed'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #3ab04b;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>
                    <p
                      v-if="project.status == 'processed'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #ffaa00;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>

                    <p
                      v-if="project.status == 'hold'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #8f8d8e;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>

                    <p
                      v-if="project.status == 'rejected'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: black;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>

                    <p
                      v-if="project.status == 'cancelled'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #f50303;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>
                    <p
                      v-if="project.status == 'upgrade'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #ee0def;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <!-- end resposnive -->
          </div>
        </div>

        <div class="row" style="margin-top: -17.5px">
          <div class="col-xl-7 col-md-7 col-sm-7" style="padding-right: 8px">
            <div
              class="card custom-card"
              style="height: 100%;"
            >
              <div class="card-body pt-2">
                <h3 style="color: black">
                  <b>Message & Notification History</b>
                </h3>
                <div style="overflow-y: auto; height: 400px; overflow-x: none">
                  <div
                    v-for="(comment, index) in comments"
                    :key="index"
                    class="row mb-1 radius"
                    style="
                      background-color: #f5f5f5;

                      border-radius: 10px;
                      padding: 10px 10px 10px 10px;
                    "
                  >
                    <div class="col-xl-12">
                      <img
                        style="width: 30; height: 30px; border-radius: 100%"
                        :src="findImgUsedr(comment.user, comment.user_type)"
                        alt=""
                      />
                      &nbsp; &nbsp;
                      <b style="color: green" v-if="comment.user.role_id == 1">{{
                        comment.user.name
                      }}   ( {{ comment.user_type}})</b>
                      <b style="color: #0170b9" v-if="comment.user.role_id == 2">{{
                        comment.user.name
                      }}   ( {{ comment.user_type}})</b>
                      <b style="color: #f21000" v-if="comment.user.role_id == 3">{{
                        comment.user.name
                      }}   ( {{ comment.user_type}})</b>
                      <b style="color: #af05f2" v-if="comment.user.role_id == 7">{{
                        comment.user.name
                      }}   ( {{ comment.user_type}})</b>
                      <b style="color: #ed731c" v-if="comment.user.role_id == 8">{{
                        comment.user.name
                      }}


                      ( {{ comment.user_type}})
                      </b>
                      &nbsp;
                    

                      <small style="color: #4c4c4c; text-transform: uppercase">
                        {{ comment.time }}, {{   dateTime(comment.date) }}</small
                      >

                      <p
                        style="
                          color: 000000;
                          font-size: 14px;
                          padding: 10px 10px 10px 10px;
                          text-align: justify;
                        "
                      >
                        {{ comment.comment }}
                      </p>
                    </div>
                  </div>
                </div>

                <form @submit.prevent="sandMessagePublick('public')">
                  <div class="row mt-1 mb-1">
                    <div class="col-xl-12 col-md-12 col-sm-12">
                      <textarea
                      required
                        v-model="msgFromPublic.comment"
                        id=""
                        rows="4"
                        class="form-control"
                        placeholder="Type your Messege here"
                      ></textarea>
                      <div class="text-end">
                        <button
                          type="submit"
                          class="btn btn-sm mt-1 text-white"
                          style="background-color: #00364f; padding: 7px 50px 7px 50px"
                        >
                          <b>send</b>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>

                <h4 style="color: black">
                  <strong>Private Messeges</strong>
                </h4>
                <!-- <div class="form-check" v-if="project.professional">
                  <input
                    @click="privateTypeSand('professional')"
                    type="radio"
                    name="flexRadioDefault"
                    id="professionalp"
                  />
                  <label
                    @click="privateTypeSand('professional')"
                    class="form-check-label"
                    for="professionalp"
                  >
                    Professional
                  </label>
                </div> -->

                <form @submit.prevent="sandMessagePublick('private')">
                <div class="row">
            <div class="col-xl-12 col-md-12 col-12-12">
              <select required  v-model="userValue" v-if="clientServiceProfessionals.length != 0"  @change="privateTypeSand" class="form-select" aria-label="Default select example">
  <option selected disabled>Select...</option>
  <option v-if="project.staff" :value="project.staff">{{project.staff.code}} {{project.staff.name}} (Staff)</option>
  <option v-if="project.retailer" :value="project.retailer">{{project.retailer.code}} {{project.retailer.name}} (Retailer)</option>
  
   <option  v-for="(prof,index) in  clientServiceProfessionals" :key="index" :value="prof">{{prof.code}} {{prof.name}} (Professional)</option>
 
</select>


<select v-model="userValue" v-else  @change="privateTypeSand" class="form-select" aria-label="Default select example">
  <option selected disabled>Select...</option>
  <option v-if="project.staff" :value="project.staff">{{project.staff.code}} {{project.staff.name}} (Staff)</option>
  <option v-if="project.retailer" :value="project.retailer">{{project.retailer.code}} {{project.retailer.name}} (Retailer)</option>
  
   <!-- <option  v-for="(prof,index) in  clientServiceProfessionals" :key="index" value="">{{prof.code}} {{prof.name}} (Professional)</option> -->
 
</select>
            </div>
          </div>
                <div style="overflow-y: auto; height: 400px; overflow-x: none">
                  <div
                    v-for="(comment1, index) in commentsprivates"
                    :key="index"
                    class="row mb-1 radius"
                    style="
                      background-color: #f5f5f5;
                      border-radius: 10px;
                      padding: 10px 10px 10px 10px;
                    "
                  >
                    <!-- <div class="col-xl-1 col-2">
                    
                    
                  </div> -->

                    <div class="col-xl-12">
                      <!-- <a
                        href="#replyCommmentHere"
                        style="text-decoreation: none; color: #00364f"
                      >
                        <div class="text-end" @click="replyData(comment1)">
                          <i class="fa fa-reply-all" aria-hidden="true"></i>
                        </div>
                      </a> -->
                      <img
                        style="width: 30; height: 30px; border-radius: 100%"
                        :src="findImgUsedr(comment1.user, comment1.user_type)"
                        alt=""
                      />
                      &nbsp; &nbsp;
                      <b style="color: green" v-if="comment1.user.role_id == 1">{{
                        comment1.user.name
                      }} ( {{ comment1.user_type}})</b>

                      <b style="color: #0170b9" v-if="comment1.user.role_id == 2">{{
                        comment1.user.name
                      }}   ( {{ comment1.user_type}})</b>
                      <b style="color: #f21000" v-if="comment1.user.role_id == 3">{{
                        comment1.user.name
                      }}   ( {{ comment1.user_type}})</b>
                      <b style="color: #ed731c" v-if="comment1.user.role_id == 8">{{
                        comment1.user.name
                      }}   ( {{ comment1.user_type}})</b>
                      &nbsp;

                      <small style="color: #4c4c4c; text-transform: uppercase">
                        {{ comment1.time }}, {{   dateTime(comment1.date) }}</small
                      >

                      <p
                        style="
                          color: 000000;
                          font-size: 14px;
                          padding: 10px 10px 10px 10px;
                          text-align: justify;
                        "
                      >
                        {{ comment1.comment }}
                       
                      </p>
                    </div>
                  </div>
                </div>

                
                  <div class="row mt-1 mb-1">
                    <div class="col-xl-12 col-md-12 col-sm-12">
                      <!-- <div
                        v-if="replyBox == 'true'"
                        class="replyData p-1"
                        id="replyData"
                        style="
                          background-color: gray;
                          color: white;
                          border-radius: 5px 5px 0px 0px;
                          font-size: 10px;
                        "
                      >
                        <strong style="color: #f21000">You: </strong>
                        <br />
                        <span id="ReplyContent">
                          {{ replyDataComment }}
                        </span>
                      </div> -->
                      <textarea
                      required
                        v-model="msgFromPublic.comment"
                        id="replyCommmentHere"
                        rows="4"
                        class="form-control"
                        placeholder="Type your Messege here"
                      ></textarea>
                      <div class="text-end">
                        <button
                          type="submit"
                          class="btn btn-sm mt-1 text-white"
                          style="background-color: #00364f; padding: 7px 50px 7px 50px"
                        >
                          <b>send</b>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="col-xl-5 col-md-5 col-sm-5" style="padding-left: 8px">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <!-- for Upgrade  -->
            <div
              v-if="upgradesData.length != 0"
              class="card  custom-card"
              style=" padding: 0px 0px 0px 0px"
            >
              <div class="card-body">
                <div class="row">
                  <div class="col-xl-12 text-start">
                    <span style="color: #00364f"><strong>Request Upgrade</strong></span>
                  </div>

                  <div class="col-12 pt-1">
                    <div class="container-fluid table-scroll">
                      <table class="table table-hover table-sm" style="font-size: 14px">
                        <tr
                          class="text tr-head table-wrapper-scroll-y rounded-circle"
                          style="background-color: white"
                        >
                          <th
                            class="text-truncate"
                            style="
                              background-color: #cdcdcd;
                              border-radius: 8px 0px 0px 8px;
                            "
                          >
                            Title
                          </th>
                          <th class="text-truncate" style="background-color: #cdcdcd">
                            Date
                          </th>
                          <th class="text-truncate" style="background-color: #cdcdcd">
                            Amount
                          </th>

                          <th class="text-truncate" style="background-color: #cdcdcd">
                            Note
                          </th>
                          <th class="text-truncate" style="background-color: #cdcdcd">
                            Remark
                          </th>
                          <th
                            style="background-color: #cdcdcd"
                            class="text-truncate text-center"
                          >
                            Status
                          </th>
                          <th
                            style="
                              background-color: #cdcdcd;
                              border-radius: 0px 8px 8px 0px;
                            "
                            class="text-truncate text-end"
                          >
                            Action
                          </th>
                        </tr>

                        <tbody class="text">
                          <tr v-for="(upgrade, index) in upgradesData" :key="index">
                            <td class="text-truncate">{{ upgrade.title }}</td>
                            <td class="text-truncate">{{   dateTime(upgrade.date )}}</td>
                            <td class="text-truncate">{{ upgrade.amt }}</td>
                            <td>{{ upgrade.note }}</td>
                            <td>{{ upgrade.remark }}</td>
                            <td class="text-truncate">
                              <span
                                v-if="upgrade.status == 'approved'"
                                style="
                                  width: 80px;
                                  background-color: #430194;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 10px 3px 10px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                {{ upgrade.status }}
                              </span>

                              <span
                                v-if="upgrade.status == 'paid'"
                                style="
                                  width: 80px;
                                  background-color: green;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 10px 3px 10px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                {{ upgrade.status }}
                              </span>

                              <span
                                v-if="upgrade.status == 'cancel'"
                                style="
                                  width: 80px;
                                  background-color: #f21000;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 10px 3px 10px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                {{ upgrade.status }}
                              </span>

                              <span
                                v-if="upgrade.status == 'pending'"
                                style="
                                  width: 80px;
                                  background-color: orange;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 10px 3px 10px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                {{ upgrade.status }}
                              </span>
                              <span
                                v-if="upgrade.status == 'reject'"
                                style="
                                  width: 80px;
                                  background-color: black;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 10px 3px 10px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                {{ upgrade.status }}
                              </span>
                            </td>
                            <td class="text-truncate">
                              <div
                                class="btn-group btn-group-sm"
                                role="group"
                                aria-label="..."
                              >
                                <span
                                  style="
                                    background-color: blue;
                                    font-size: 8pt;
                                    width: 25px;
                                    padding: 5px 5px 5px 5px;
                                  "
                                  class="btn btn-sm text-white"
                                  @click="
                                    openWindow(
                                      `${
                                        this.$store.state.imgUrl +
                                        '/clientservice/' +
                                        this.$route.params.id +
                                        '/' +
                                        upgrade.document
                                      }`
                                    )
                                  "
                                >
                                  <i class="fa fa-cloud-download" aria-hidden="true"></i>
                                </span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <!-- profesonal area -->
                <!-- for Upgrade  -->
                <!-- :style="(upgradesData.length != 0) ? 'margin-top: -17.5px;' : '' " -->
                <div  v-if="project.client_service_professional"  :style="(upgradesData.length != 0) ? 'margin-top: -17.5px;' : '' ">
            <div
           
          
           class="card custom-card"
         v-for="(profesonal,index) in project.client_service_professional" :key="index"
         >
           <div class="card-body">
             <div class="row radius" style="line-height: 1.3em; border-radius: 10px">
               <div class="col">
                 <!-- mobile responsive -->
                 <img
                   class="d-xl-none d-md-none d-block"
                   style="
                     width: 100%;
                     height: 100%;
                     margin-left: -10px;
                     border-radius: 100%;
                   "
                   :src="findImgUsedr(profesonal.professional, 'professional')"
                   alt=""
                 />
                 <!-- pc responsive -->
                 <img
                   class="d-xl-block d-md-block d-none"
                   style="
                     width: 80%;
                     height: 85%;
                     border-radius: 100%;
                     margin-left: -10px;
                   "
                   :src="findImgUsedr(profesonal.professional, 'professional')"
                   alt=""
                 />
               </div>
               <div class="col-10">
                 <b style="color: #f21300">Vakilgiri Professional</b>
                 <div class="text">
                   <b>Name : </b>
                   <span v-if="profesonal.professional"> {{ profesonal.professional.name }} ( {{ profesonal.professional.code }})</span>
                 </div>
                 <div class="text">
                   <b>Contact Number : </b>
                   <span v-if="profesonal.professional"> +91- {{ profesonal.professional.phone }} </span>
                 </div>
                 <div class="text">
                   <b>Email : </b>
                   <span v-if="profesonal.professional"> {{ profesonal.professional.email }}</span>
                 </div>

                 <div class="text">
                   <b>status : </b>
                   <span v-if="profesonal"> {{ profesonal.status }}</span>
                 </div>
                 <div class="text" v-if="profesonal.remark">
                   <b>Remark : </b>
                   <span> {{ profesonal.remark }}</span>
                 </div>
               </div>
             </div>
           </div>
         </div>
          </div>
            <!-- end of the professional area  -->

            <!-- for Upgrade  -->


            <div
            :style="(upgradesData.length != 0) ? 'margin-top: -17.5px;' : '' "
              v-if="project.staff"
              class="card custom-card"
            
            >
              <div class="card-body">
                <div class="row radius" style="line-height: 1.3em; border-radius: 10px">
                  <div class="col">
                    <!-- mobile responsive -->
                    <img
                      class="d-xl-none d-md-none d-block"
                      style="
                        width: 100%;
                        height: 100%;
                        margin-left: -10px;
                        border-radius: 100%;
                      "
                      :src="findImgUsedr(project.staff, 'staff')"
                      alt=""
                    />
                    <!-- pc responsive -->
                    <img
                      class="d-xl-block d-md-block d-none"
                      style="
                        width: 80%;
                        height: 85%;
                        border-radius: 100%;
                        margin-left: -10px;
                      "
                      :src="findImgUsedr(project.staff, 'staff')"
                      alt=""
                    />
                  </div>
                  <div class="col-10">
                    <b style="color: #f21300">Vakilgiri Expert</b>
                    <div class="text">
                      <b>Name : </b>
                      <span v-if="project.staff"> {{ project.staff.name }}</span>
                    </div>
                    <div class="text">
                      <b>Contact Number : </b>
                      <span v-if="project.staff"> +91- {{ project.staff.phone }} </span>
                    </div>
                    <div class="text">
                      <b>Email : </b>
                      <span v-if="project.staff"> {{ project.staff.email }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="Tasks.length != 0"
              class="card custom-card"
              :style="(project.staff) ? 'margin-top: -17.5px;' : '' "
            >
              <div class="card-body">
                <div class="row">
                  <div class="col-xl-3 col-md-3 col-sm-3">
                    <b
                      class="font"
                      style="color: black; font-weight: 600; font-size: 16px"
                      >Tasks</b
                    >
                  </div>
                  <div class="col-xl-9 col-md-9 col-sm-9">
                    <div class="progress">
                      <div
                        id="progressBarProject"
                        class="progress-bar bg-danger"
                        role="progressbar"
                        aria-valuenow="20"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        v-if="Tasks.length != 0"
                        :style="`width: ${this.progressForProject()}%;`"
                      >
                        {{ taskProgress }}%
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div>
                      <div
                        class="form-check mt-1"
                        v-for="(task, index) in Tasks"
                        :key="index"
                      >
                        <input
                          v-if="task.status == 'complete'"
                          class="form-check-input"
                          type="checkbox"
                          disabled
                          checked
                        />
                        <input
                          v-else
                          class="form-check-input"
                          type="checkbox"
                          id="flexCheckDisabled"
                          disabled
                        />
                        <label style="color: #00364f; font-weight: 600">
                          <span v-if="task.step"> {{ task.step.title }}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="requestdocuments.length != 0"
              class="card custom-card"
              :style="(Tasks.length != 0) ? 'margin-top: -17.5px;' : '' "
            >
              <div class="card-body font">
                <div class="row text">
                



                <div class="col-12">
                  <h4><b style="color: black">Requested Documents</b></h4>
                  <div class="container-fluid table-scroll">
                    <table class="table table-hover table-sm" style="font-size: 14px">
                      <tr
                        class="text tr-head table-wrapper-scroll-y rounded-circle"
                        style="background-color: white"
                      >
                        <th
                          class="text-truncate"
                          style="
                            background-color: #cdcdcd;
                            border-radius: 8px 0px 0px 8px;
                          "
                        >
                          +
                        </th>
                        <th class="text-truncate" style="background-color: #cdcdcd">
                          Title
                        </th>
                        <th class="text-truncate" style="background-color: #cdcdcd">
                          Doccument
                        </th>
                      

                        <th
                          style="
                            background-color: #cdcdcd;
                            border-radius: 0px 8px 8px 0px;
                          "
                          class="text-truncate text-end"
                        >
                        Remark
                        </th>
                      </tr>

                      <tbody class="text">
                        <tr v-for="(clentDocc, index) in requestdocuments" :key="index">
                          <td class="text-truncate">
                            <span v-if="clentDocc.status == 'complete'">
                              <i
                        class="fa fa-check"
                        aria-hidden="true"
                        style="color: green"
                      ></i>
                            </span>
                            <span v-else>
                              <font-awesome-icon icon="remove" style="color: red" />
                            </span>
                          
                          </td>
                          <td class="text-truncate">{{ clentDocc.title }}</td>
                          <td class="text-truncate">
                            <span v-if="clentDocc.status == 'complete'">
                            
                              
                              <span @click="openWindowClientDoccRequest(clentDocc)"
                        ><i class="fa fa-download" aria-hidden="true"></i></span
                    >


                            </span>
                          </td>
                          <td class="text-truncate">{{ clentDocc.description }}</td>
                         
                        
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              </div>
            </div>
            <div
              v-if="project.client_service_document"
              class="card custom-card"
              :style="(requestdocuments.length != 0) ? 'margin-top: -17.5px;' : '' "
            >
              <div
                class="card-header"
               
                style="
                  background-color: #00364f;
                  border-radius: 10px 10px 0px 0px;
                  padding: 10px 10px 10px 10px;
                
                "
              >
                <div class="col-md-8 text-white">
                  <h4><b class="text-white">DOCS UPLOADED BY RETAILER</b></h4>
                </div>
                <div class="col-md-4 text-end"></div>
              </div>
              <div class="card-body pt-1">
                <div v-if="project.client_service_document">
                  <div
                    class="row"
                    style="padding-top: 3px"
                    v-for="(docc, index) in project.client_service_document"
                    :key="index"
                  >
                    <div class="col-xl-4 col-md-4 col-6 text">
                      <b v-if="docc.document">{{ docc.caption }}</b>
                    </div>
                    <div class="col-xl-4 col-md-4 d-none d-md-block d-xl-block">
                      <span
                      @click="
                      downloadImg(docc)
                      "
                      ><b style="color: red; cursor: pointer">Download</b></span
                    >
                    </div>
                    <div class="col-xl-4 col-md-4 col-6 text text-end">
                      <b class="d-xl-none d-md-none d-block" style="color: red"
                        >Download</b
                      >
                      <small>{{dateTimeWithTime(docc.updated_at)}}</small>
                    </div>
                    <hr style="margin-top: -1px" />
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="allDoccs.length != 0"
              class="card custom-card"
              style="margin-top: -17.5px; "
            >
              <div
                class="card-header"
                style="
                  background-color: #00364f;
                  border-radius: 10px 10px 0px 0px;
                  padding: 10px 10px 10px 10px;
                "
              >
                <div class="col-md-12 text-white">
                  <h4>
                    <b class="text-white">DOCS UPLOADED BY PROFESSIONAL</b>
                  </h4>
                </div>
              </div>
              <div class="card-body">
                <div
                  class="row"
                  style="padding-top: 3px"
                  v-for="(certificate, index) in allDoccs"
                  :key="index"
                >
                  <div class="col-xl-4 col-md-4 col-6 text">
                    <b>{{ certificate.title }}</b>
                  </div>
                  <div class="col-xl-4 col-md-4 d-none d-md-block d-xl-block">
                    <span
                      @click="
                        openWindow(
                          `${
                            this.$store.state.imgUrl +
                            '/clientservice/' +
                            this.$route.params.id +
                            '/' +
                            certificate.certificate
                          }`
                        )
                      "
                      ><b style="color: red; cursor: pointer">Download</b></span
                    >
                  </div>
                  <div class="col-xl-4 col-md-4 col-6 text text-end">
                    <b class="d-xl-none d-md-none d-block" style="color: red">Download</b>
                    <small
                      >{{ dateTime(certificate.date) }}
                     </small
                    >
                  </div>
                  <hr style="margin-top: -1px" />
                </div>
              </div>
            </div>

            <div
              v-if="challans.length != 0"
              class="card custom-card"
              style="margin-top: -17.5px; "
            >
              <div
                class="card-header"
                style="
                  background-color: #00364f;
                  border-radius: 10px 10px 0px 0px;
                  padding: 10px 10px 10px 10px;
                "
              >
                <h4><b class="text-white">CHALANS</b></h4>
              </div>
              <div class="card-body">
                <div
                  class="row"
                  style="padding-top: 2px"
                  v-for="(challan, index) in challans"
                  :key="index"
                >
                  <div class="col-xl-4 col-md-4 col-6 text">
                    <b>{{ challan.title }}</b>
                  </div>
                  <div class="col-xl-4 col-md-4 d-none d-md-block d-xl-block">
                    <b class="d-xl-block d-md-block d-none">{{ challan.challanNo }}</b>
                  </div>
                  <div class="col-xl-4 col-md-4 col-6 text text-end">
                    <small>Amount : Rs.{{ challan.amt }}</small
                    ><br />
                    <small style="color: red; cursor: pointer"
                      ><b
                        ><span
                          @click="
                            openWindow(
                              `${
                                this.$store.state.imgUrl +
                                '/clientservice/' +
                                this.$route.params.id +
                                '/' +
                                challan.challan
                              }`
                            )
                          "
                          >Download</span
                        >
                      </b></small
                    ><br />
                    <small class="d-xl-none d-md-none d-block">{{
                      challan.challanNo
                    }}</small>
                    <small>{{   dateTimeWithTime(challan.updated_at )}} </small>
                  </div>
                  <hr />
                </div>
              </div>
              <div
                class="card-footer text-white"
                style="
                  background-color: #00364f;
                  border-radius: 0px 0px 10px 10px;
                  padding: 8px 5px 8px 5px;
                "
              >
                <div class="row">
                  <div class="col-9 text-start"><b>Total Amount</b></div>

                  <div class="col-3 text-end">
                    <b>Rs. {{ totalAmtChallan }}</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>

  <!-- model  -->
  <!-- Button trigger modal -->

  <div v-if="loading == true">
    <Spinner />
  </div>
</template>

//
<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import moment from "moment";
import Banner from "../../../components/super-admin/comman/Banner.vue";
import Spinner from "../../../components/super-admin/comman/Spinner.vue";
// import Form from "vform";
import jQuery from "jquery";
// var _ = require("lodash");
let $ = jQuery;
export default {
  name: "Project",
  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      clientServiceProfessionals : [],
      taskProgress: 0,
      msgFromPublic: {
        client_service_id: null,
        type: "",
        touser_id: null,
        touser_type: "",
        remark: "",
        service_id: null,
        comment: "",
      },
      requestdocuments: [],
      loading: false,
      challans: [],
      services: [],
      project: {},
      userData: {},
      allDoccs: [],

      certificateForm: {
        service_id: null,
        client_service_id: "",
        title: "",
        note: "",
        certificateAthority: "",
        certificateNo: "",
        expireDate: "",
        date: "",
        certificate: "",

        imagePrefix: "certificate",
      },

      chalansForm: {
        imagePrefix: "challan",
        client_service_id: "",
        service_id: null,

        title: "",
        challanAthority: "",
        challanNo: "",
        date: "",
        expireDate: "",
        validity: "",
        challan: "",
        amt: "",
        note: "",
        remark: "",
        status: "",
        ipAddress: "",
        thumbnail: "yes",
      },
      clientDoccumentsfrom: {
        title: "",
      },
      upgradeData: [],
      upgradeForm: {
        imagePrefix: "Upgrade",
        client_service_id: "",
        service_id: null,

        title: "",

        date: "",
        document: "",
        amt: "",
        note: "",
        remark: "",
        status: "",
        ipAddress: "",
      },

      uploadDocc_form: {
        client_service_id: null,
        service_document_id: null,
        alt: "",
        caption: "",
        serviceDocument: "",
        cwidth: "",
        cheight: "",
        cx: "",
        cy: "",
      },
      upgradeEdit: "false",
      upgradesData: [],
      extensionCertificate: "",
      msgImgError: "",
      extensionChallan: "",
      totalAmtChallan: 0,
      TasksData: [],
      Tasks: [],
      comments: [],
      commentsprivates: [],
      serviceModules: [],
      replyBox: "false",
      replyDataComment: "",
      srvMod: [],
      userValue: {}
    };
  },
  methods: {
    openWindowClientDoccRequest(docc) {
      var url = "";
      if (
        this.$store.state.imgUrl +
        "/clientservice/" +
        this.project.id +
        "/thumbs/" +
        docc.attachment
      ) {
        url =
          this.$store.state.imgUrl +
          "/clientservice/" +
          this.project.id +
          "/thumbs/" +
          docc.attachment;
      }

      if (
        this.$store.state.imgUrl +
        "/clientservice/" +
        this.project.id +
        "/thumbs/" +
        docc.attachment
      ) {
        url =
          this.$store.state.imgUrl +
          "/clientservice/" +
          this.project.id +
          "/" +
          docc.attachment;
      }

      window.open(`${url}`);
    },
    progressForProject() {
      var complete = 0;
      for (var i = 0; i < this.Tasks.length; i++) {
        if (this.Tasks[i].status == "complete") {
          complete += 1;
        }
      }
      var n = 100 / this.Tasks.length;

      this.taskProgress = n * complete;
      return this.taskProgress;
    },
    replyData(value) {
      this.replyBox = "true";
      console.log(value.comment);
      this.replyDataComment = value.comment;

      this.msgFromPublic.touser_id = value.user.id;
      this.msgFromPublic.touser_type = "professional";
      console.log(this.msgFromPublic);
    },
    // comment area
    getAllComments() {
      this.loading = true;
      this.$axios
        .get(
          `superadmin/clientservicecomment?client_service_id=${this.$route.params.id}`,
          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )
        .then((res) => {
          this.comments = res.data.data.data;
        });

      this.$axios
        .get(
          `superadmin/clientservicecomment?type=private&client_service_id=${this.$route.params.id}`,
          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )
        .then((res) => {
          this.commentsprivates = res.data.data.data;
        })
        .finally(() => (this.loading = false));
    },
    privateTypeSand() {
      console.log(this.userValue);
      if (this.userValue.role_id == 3) {
        this.msgFromPublic.touser_id = this.userValue.id;
        this.msgFromPublic.touser_type = "professional";
      }
      if (this.userValue.role_id == 2) {
        this.msgFromPublic.touser_id = this.userValue.id;
        this.msgFromPublic.touser_type = "staff";
      }
      if (this.userValue.role_id == 8) {
        this.msgFromPublic.touser_id = this.userValue.id;
        this.msgFromPublic.touser_type = "retailer";
      }

      console.log(this.msgFromPublic);
    },
    sandMessagePublick(type) {
      var userData = {};
      userData = JSON.parse(localStorage.getItem("userData"));
      console.log(userData);
      // if (type == "private") {
      //   this.msgFromPublic.touser_id = userData.id;
      //   if (userData.role) {
      //     this.msgFromPublic.touser_type = userData.role.name;
      //   }
      // }


      this.msgFromPublic.type = type;
      this.msgFromPublic.client_service_id = this.project.id;
      this.msgFromPublic.service_id = this.project.service_id;
      console.log(this.project);
      console.log(this.msgFromPublic);

      this.loading = true;
      this.$axios
        .post(`superadmin/clientservicecomment`, this.msgFromPublic, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data);

          toast.success("Comment Sand Successfully", {
            autoClose: 1000,
          });
          this.msgFromPublic = {};
          this.getAllComments();
          // $("#challansModal").modal("hide");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          console.log(error.response.data.error.service_id[0]);

          this.$swal.fire({
            confirmButtonColor: "#00364f",
            icon: "error",
            title: "Oops...",
            text: error.response.data.error.service_id[0],
          });
        });




        
      this.replyBox = 'false'
    },

    dateTime(value) {
      console.log(value);
       if(value){
  return moment(value).format("DD-MM-YYYY");
      }
    
    },
    dateTimeWithTime(value) {
      console.log(value);
      if(value){
return moment(value).format("DD-MM-YYYY,  HH:mm a");
      }
      
    },
    openWindow(url) {
      window.open(`${url}`);
    },

    loadTask() {
      this.$axios
        .get(
          `superadmin/clientserviceprofessionalstep?client_service_id=${this.$route.params.id}`,
          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )
        .then((res) => {
          this.Tasks = res.data.data.data;
        });
    },
    findImgUsedr(user, user_type) {
      var img = "";
      var type = "";

      if (user_type == "superAdmin") {
        type = "superadmin";
      } else {
        type = user_type;
      }
      if (user.photo != null) {
        img =
          this.$store.state.imgUrl + "/" + type + "/" + user.id + "/thumbs/" + user.photo;
      } else {
        img = this.$store.state.placeholderImg;
      }
      return img;
    },
    saveTask(event, task) {
      var form = {};
      if (event.target.checked == true) {
        console.log(event.target.checked);
        console.log(task);

        form.status = "complete";
        form.remark = "";
      }
      if (event.target.checked == false) {
        form.status = "pendding";
        form.remark = "";
      }
      this.loading = true;
      this.$axios
        .post(`superadmin/clientserviceprofessionalstep/status/${task.id}`, form, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data);

          toast.success("The Task successfully Done", {
            autoClose: 1000,
          });
          this.getProfessionalTaskCompleted();
          // $("#challansModal").modal("hide");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          console.log(error.response);

          this.$swal.fire({
            confirmButtonColor: "#00364f",
            icon: "error",
            title: "Oops...",
            text: error.response.data.error,
          });
        });
    },

    getChalans() {
      var amt = 0;
      this.$axios
        .get(
          `superadmin/clientservicechallan?client_service_id=${this.$route.params.id}`,
          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )
        .then((res) => {
          // console.log(res.data);
          this.challans = res.data.data.data;
          for (var i = 0; i < this.challans.length; i++) {
            amt += parseInt(this.challans[i].amt);
          }

          this.totalAmtChallan = amt;
        });
    },
    uploadUpgrade(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size <= 2 * (1024 * 1024)) {
        // this.adharFrontImg = URL.createObjectURL(e.target.files["0"]);
        this.upgradeForm.document = e.target.files["0"];
        var fileName;
        fileName = document.querySelector("#uploadUpgrade").value;
        this.extensionChallan = fileName.split(".").pop();

        // if (this.extensionChallan == "pdf") {
        //   this.adharFrontImg = "/assets/image/pdf.png";
        // }
        return false;
      } else {
        this.msgImgError = "File Size Too Big 2MB";
      }
    },
    UploadChallan(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size <= 2 * (1024 * 1024)) {
        // this.adharFrontImg = URL.createObjectURL(e.target.files["0"]);
        this.chalansForm.challan = e.target.files["0"];
        var fileName;
        fileName = document.querySelector("#challan").value;
        this.extensionChallan = fileName.split(".").pop();

        // if (this.extensionChallan == "pdf") {
        //   this.adharFrontImg = "/assets/image/pdf.png";
        // }
        return false;
      } else {
        this.msgImgError = "File Size Too Big 2MB";
      }
    },
    UploadCertificate(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size <= 2 * (1024 * 1024)) {
        // this.adharFrontImg = URL.createObjectURL(e.target.files["0"]);
        this.certificateForm.certificate = e.target.files["0"];
        var fileName;
        fileName = document.querySelector("#certificate").value;
        this.extensionCertificate = fileName.split(".").pop();

        // if (this.extensionChallan == "pdf") {
        //   this.adharFrontImg = "/assets/image/pdf.png";
        // }

        return false;
      } else {
        this.msgImgError = "File Size Too Big 2MB";
      }
    },
    createChalans() {
      this.chalansForm.client_service_id = this.project.id;
      this.chalansForm.service_id = this.project.service_id;

      // console.log(this.chalansForm);

      const formData = new FormData();
      const keys = Object.keys(this.chalansForm);
      for (let n = 0; n < keys.length; n += 1) {
        if (this.chalansForm[keys[n]] !== null) {
          formData.append(keys[n], this.chalansForm[keys[n]]);
        }
      }
      this.loading = true;
      this.$axios
        .post("superadmin/clientservicechallan", formData, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data);
          res;
          toast.success("The form was sent successfully", {
            autoClose: 1000,
          });
          this.getChalans();
          $("#challansModal").modal("hide");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          // res
          console.log(error.response);
        });
    },

    clientServiceDoccumentRequestGet() {
      this.loading = true;
      this.$axios
        .get(
          `superadmin/clientservicerequestdocument?client_service_id=${this.$route.params.id}`,
          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )
        .then((res) => {
          // console.log("*************************");
          // console.log(res.data.data.data);
          this.requestdocuments = res.data.data.data;
          // console.log("*************************");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          console.log(error.response);
        });
    },
    clientServiceDoccumentRequestPost() {
      this.clientDoccumentsfrom.client_service_id = this.project.id;
      this.clientDoccumentsfrom.service_id = this.project.service_id;

      // console.log(this.chalansForm);

      this.loading = true;
      this.$axios
        .post("superadmin/clientservicerequestdocument", this.clientDoccumentsfrom, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data);
          res;
          toast.success("The Request was sent successfully", {
            autoClose: 1000,
          });
          this.clientServiceDoccumentRequestGet();
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          // res
          console.log(error.response);
        });
    },
    getUpgrade() {
      this.loading = true;
      this.$axios
        .get(
          `superadmin/clientserviceupgrade?client_service_id=${this.$route.params.id}`,
          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )
        .then((res) => {
          // console.log("*************************");
          // console.log(res.data.data.data);
          this.upgradesData = res.data.data.data;
          // console.log("*************************");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          console.log(error.response);
        });
    },
    createUpgrade() {
      this.upgradeForm.client_service_id = this.project.id;
      this.upgradeForm.service_id = this.project.service_id;

      // console.log(this.upgradeForm);

      const formData = new FormData();
      const keys = Object.keys(this.upgradeForm);
      for (let n = 0; n < keys.length; n += 1) {
        if (this.upgradeForm[keys[n]] !== null) {
          formData.append(keys[n], this.upgradeForm[keys[n]]);
        }
      }
      this.loading = true;
      this.$axios
        .post("superadmin/clientserviceupgrade", formData, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data);
          res;
          toast.success("The form was sent successfully", {
            autoClose: 1000,
          });
          this.getUpgrade();
          this.upgradeForm = {};
          $("#upgradeModel").modal("hide");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          console.log(error.response);
        });
    },
    upgradeDelete(upgrade) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be Delete it",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#00364f",
          cancelButtonColor: "#f21000",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.loading = true;
            this.$axios
              .delete(`superadmin/clientserviceupgrade/${upgrade.id}`, {
                headers: { Authorization: "Bearer " + localStorage.accessToken },
              })
              .then((res) => {
                res;
                toast.success("Deleted Succesfully", {
                  autoClose: 1000,
                });
                this.getUpgrade();
              })
              .finally(() => (this.loading = false))
              .catch((error) => {
                error;
              });
          }
        });
    },

    upgradeDataAsign(upgrade) {
      // console.log(upgrade);
      this.upgradeEdit = "true";
      this.upgradeForm.id = upgrade.id;
      this.upgradeForm.client_service_id = upgrade.client_service_id;
      this.upgradeForm.service_id = upgrade.service_id;
      this.upgradeForm.title = upgrade.title;
      this.upgradeForm.date = upgrade.date;
      this.upgradeForm.amt = upgrade.amt;
      this.upgradeForm.note = upgrade.note;
      // console.log(this.upgradeForm);
    },
    updateUpgrade() {
      this.upgradeForm.client_service_id = this.project.id;
      this.upgradeForm.service_id = this.project.service_id;

      // console.log(this.upgradeForm);

      const formData = new FormData();
      const keys = Object.keys(this.upgradeForm);
      for (let n = 0; n < keys.length; n += 1) {
        if (this.upgradeForm[keys[n]] !== null) {
          formData.append(keys[n], this.upgradeForm[keys[n]]);
        }
      }
      this.loading = true;
      this.$axios
        .post(
          `superadmin/clientserviceupgrade/${this.upgradeForm.id}?_method=PUT`,
          formData,
          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )
        .then((res) => {
          // console.log(res.data);
          res;

          toast.success("The form was sent successfully", {
            autoClose: 1000,
          });
          this.getUpgrade();
          this.upgradeForm = {};
          $("#upgradeModel").modal("hide");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          console.log(error.response);
        });
    },

    createCertificateByPrfesional() {
      this.certificateForm.client_service_id = this.project.id;
      this.certificateForm.service_id = this.project.service_id;
      // console.log(this.certificateForm);

      const formData = new FormData();
      const keys = Object.keys(this.certificateForm);
      for (let n = 0; n < keys.length; n += 1) {
        if (this.certificateForm[keys[n]] !== null) {
          formData.append(keys[n], this.certificateForm[keys[n]]);
        }
      }
      this.loading = true;
      this.$axios
        .post("superadmin/clientservicecertificate", formData, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data);
          res;
          toast.success("The form was sent successfully", {
            autoClose: 1000,
          });
          // this.getChalans();
          // $("#challansModal").modal("hide");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          console.log(error.response);
        });
    },
    uploadDocuments(event, docc) {
      this.uploadDocc_form.serviceDocument = event.target.files["0"];
      this.uploadDocc_form.service_document_id = docc.id;
      this.uploadDocc_form.client_service_id = this.$route.params.id;
      this.uploadDocc_form.alt = docc.document;
      this.uploadDocc_form.caption = docc.document;

      // console.log('-------------------------------------------------------------------------------')

      const formData = new FormData();
      const keys = Object.keys(this.uploadDocc_form);
      for (let n = 0; n < keys.length; n += 1) {
        if (this.uploadDocc_form[keys[n]] !== null) {
          formData.append(keys[n], this.uploadDocc_form[keys[n]]);
        }
      }
      this.$axios
        .post("retailer/clientservicedocument", formData, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data);
          res;
          // alert(res.data)
          this.$swal.fire({
            position: "top-end",
            icon: "success",
            title: "The form was sent successfully.",
            showConfirmButton: false,
            timer: 1500,
          });
          this.clientserviceDocumentLoad();
        });
    },
    LoadDocuments() {
      // var service_id = service.id;

      this.$axios
        .get(
          `superadmin/clientservicecertificate?client_service_id=${this.$route.params.id}`,
          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )
        .then((res) => {
          //   console.log(res.data)
          this.allDoccs = res.data.data.data;
        });
    },

    loadUserApi() {
      this.$axios
        .post(
          `superadmin/user`,
          {},
          { headers: { Authorization: "Bearer " + localStorage.accessToken } }
        )
        .then((res) => {
          // console.log(res.data)
          this.userData = res.data;
        });
    },
    downloadImg(link) {
      var url;
      if (
        this.$store.state.imgUrl +
        "/clientservice/" +
        link.client_service_id +
        "/thumbs/" +
        link.document
      ) {
        url =
          this.$store.state.imgUrl +
          "/clientservice/" +
          link.client_service_id +
          "/thumbs/" +
          link.document;
      }
      if (
        this.$store.state.imgUrl +
        "/clientservice/" +
        link.client_service_id +
        "/" +
        link.document
      ) {
        url =
          this.$store.state.imgUrl +
          "/clientservice/" +
          link.client_service_id +
          "/" +
          link.document;
      }
      // console.log('downloading : ', url);
this.openWindow(url)
      // return url;
    },
    loadProject() {
      var professionals = []
      this.$axios
        .get(`superadmin/clientservice/${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.project = res.data.data;
          console.log('-------***********************************-----')
          // console.log(professionals)
          var clientPro = this.project.client_service_professional
          console.log(this.project.client_service_professional)
          if(clientPro){
              for(var i = 0 ; i < clientPro.length ; i++){
                professionals.push(clientPro[i].professional)
              }
              // this.clientServiceProfessionals = this.project.client_service_professional.professional
              console.log('----+++++++++++++++++++++++++++++--------')
          console.log(professionals)
          console.log('------------')
          
          this.clientServiceProfessionals = professionals
            }
       

          // console.log(res.data.data);
          this.loadModules();
        });
    },

    loadModules() {
      var mod = [];
      mod = JSON.parse(this.project.serviceModules);
      for (var i = 0; i < mod.length; i++) {
        var data = `&serviceModulesIds[]=${mod[i]}`;
        this.srvMod.push(data);
      }

      this.$axios
        .get(
          `superadmin/servicemodule?client_service_id=${this.$route.params.id}${this.srvMod}`,

          { headers: { Authorization: "Bearer " + localStorage.accessToken } }
        )
        .then((res) => {
          this.serviceModules = res.data.data.data;
        });
    },
    loadPageTitle(){
      console.log('--------------------------');
        
        document.title =this.$store.state.superAdminPageTitles.clientsServiceView
    console.log(this.$store.state.superAdminPageTitles.clientsServiceView)
    console.log('--------------------------');
    }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadProject();
      this.loadUserApi();
      this.getChalans();
      this.getUpgrade();
      this.LoadDocuments();
      this.loadTask();
      this.clientServiceDoccumentRequestGet();
      this.getAllComments();
      
      this.loadPageTitle()
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}

.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #f21300;
  border-color: #f21300;
}

h4 {
  font-size: 1rem;
}
</style>
